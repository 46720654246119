<template>
  <div>
    <div class="user-item">
      <img src="@/assets/images/avatar.png" alt="">
      <div class="content">
        <p
          v-for="field in userFields"
          :key="field">
          {{ $t(field) }}：{{ user[field] }}
        </p>
        <p>{{ $t('role') }}：{{ user | roleFormat }}</p>
        <p>{{ $t('team') }}：{{ user | teamFormat }}</p>
      </div>
      <el-dropdown
        v-if="hasPermission($permissionFunctionCode.MANAGE_USER)">
        <em class="el-icon-more"></em>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            @click.native="handleUser('edit')">
            {{ $t('edit') }}
          </el-dropdown-item>
          <el-dropdown-item
            @click.native="handleRole">
            {{ $t('role') }}
          </el-dropdown-item>
          <template v-if="$store.state.user.id!==user.id">
            <el-dropdown-item
              v-if="teams.length"
              @click.native="handleUser('transfer')">
              {{ $t('transfer') }}
            </el-dropdown-item>
            <el-dropdown-item
              :disabled="currentUser.email === user.email"
              @click.native="handleUser('lockOrUnlock')">
              {{ user.enable ? $t('lock') : $t('unlock') }}
            </el-dropdown-item>
            <el-dropdown-item
              :style="{color: currentUser.email === user.email?'#bbb':'#F56C6C'}"
              :disabled="currentUser.email === user.email"
              @click.native="handleUser('delete')">
              {{ $t('delete') }}
            </el-dropdown-item>
          </template>

        </el-dropdown-menu>
      </el-dropdown>
      <!--启用/禁用标识-->
      <span :style="{background: user.enable?'#67C23A':'#F56C6C'}"></span>
      <div class="user-type" :class="setRoleColor()">
        <span>{{ user.roles | userTypeFormat }}</span>
      </div>
    </div>

    <!--编辑用户信息-->
    <base-form-add-or-edit
      v-if="editUserVisible"
      :visible="editUserVisible"
      :url-save="$apis.saveUserNew"
      :url-update="$apis.saveUserNew"
      :fields="userFormFields"
      labelWidthCn="100"
      labelWidthEn="200"
      width="50%"
      @closed="editUserVisible=false"
      @submit-success="$emit('handle-success')">
    </base-form-add-or-edit>

    <!--操作角色-->
    <el-dialog
      :visible.sync="roleVisible"
      custom-class="role-dialog"
      width="50%">
      <base-title
        slot="title"
        :text="$t('roleList')">
      </base-title>
      <div style="display: flex;justify-content: center">
        <el-transfer
          :titles="[$t('optional'),$t('selected')]"
          :props="{key:'id',label:'name'}"
          v-model="userRoleIds"
          :data="roleList">
        </el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button

          round
          @click="roleVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"

          round
          :disabled="!userRoleIds.length"
          @click="submitRole">
          {{ $t('submit') }}
        </el-button>
      </span>
    </el-dialog>

    <!--用户转移-->
    <el-dialog
      :visible.sync="transferVisible"
      custom-class="transfer-dialog"
      width="30%">
      <base-title
        slot="title"
        :text="$t('chooseTransferTeam')">
      </base-title>
      <div class="team-list">
        <p
          v-for="team in teamList"
          :key="team.id"
          :class="{active:team.isIn}"
          @click="team.isIn = !team.isIn">
          {{ team.name }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button

          round
          @click="transferVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"

          round
          :disabled="!selectedTeams.length"
          @click="submitTransfer">
          {{ $t('confirm') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'UserCard',
  mixins: [baseMixin],
  props: {
    company: {
      type: Object,
      required: false,
      default: () => ({})
    },
    user: {
      type: Object,
      required: true
    },
    teams: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      editUserVisible: false,
      userFields: [
        'email',
        'firstName',
        'lastName',
        'homePhoneNumber',
        'salutation',
        'officePhoneNumber',
        'designation'
      ],
      userFormFields: [
        {
          fieldName: 'id',
          fieldType: 'invisible'
        },
        {
          fieldName: 'firstName'
        },
        {
          fieldName: 'lastName'
        },
        {
          fieldName: 'salutation'
        },
        {
          fieldName: 'homePhoneNumber',
          formConfig: {
            type: 'phone'
          }
        },
        {
          fieldName: 'officePhoneNumber',
          formConfig: {
            type: 'phone'
          }
        },
        {
          fieldName: 'designation'
        }
      ],
      currentTeam: {},
      userRoleIds: [],
      roleList: [],
      teamList: [],
      transferVisible: false,
      roleVisible: false
    }
  },
  computed: {
    listenChange () {
      return {
        user: this.user,
        teams: this.teams
      }
    },
    selectedTeams () {
      return this.teamList.filter(item => item.isIn)
    },
    currentUser () {
      return this.$store.state.user
    }
  },
  methods: {
    getRolesByCompany () {
      /**
       * 根据企业获取角色列表
       */
      this.tableLoading = true
      this.$request.get({
        url: `${this.$apis.roleBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${this.company.id}`
      }).then(res => {
        if (res?.code === 1000) {
          this.roleList = res.data.records || []
        }
      })
    },

    editUser () {
      /**
       * 编辑用户
       */
      const user = this.user
      this.userFormFields.forEach(item => {
        item.default = user[item.fieldName]
      })
      this.editUserVisible = true
    },

    deleteUser () {
      /**
       * 删除用户
       */
      const user = this.user
      this.$confirm(this.$t('deletePermanent'), this.$t('deleteUser'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$request.post({
          url: this.$apis.deleteUserNew,
          data: [user.id]
        }).then(res => {
          if (res?.code === 1000) {
            this.$emit('handle-success')
            this.$message.success(this.$t('dataDeleteSuccess'))
          }
        })
      }).catch(() => {
      })
    },

    lockOrUnlockUser () {
      /**
       * 禁用或启用用户
       */
      const user = this.user
      const title = user.enable ? this.$t('lockUserTip') : this.$t('unlockUserTio')
      const body = user.enable ? this.$t('lockUser') : this.$t('unlockUser')
      this.$confirm(body, title, {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$request.post({
          url: this.$apis.saveUserNew,
          data: {
            id: user.id,
            enable: !user.enable
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.$emit('handle-success')
            this.$message.success(this.$t(user.enable ? 'dataDisabledSuccess' : 'dataEnableSuccess'))
          }
        })
      }).catch(() => {
      })
    },

    transfer () {
      /**
       * 团队转移操作
       */
      this.transferVisible = true
    },

    handleUser (command) {
      /**
       * 操作用户
       */
      const doList = {
        edit: this.editUser,
        lockOrUnlock: this.lockOrUnlockUser,
        transfer: this.transfer,
        delete: this.deleteUser
      }
      doList[command]()
    },

    handleRole () {
      /**
       * 操作角色
       */
      this.userRoleIds = (this.user.roles || []).map(item => item.id)
      this.getRolesByCompany()
      this.roleVisible = true
    },

    submitTransfer () {
      const teamIds = []
      this.teamList.forEach(item => {
        if (item.isIn) {
          teamIds.push(item.id)
        }
      })
      if (teamIds.length) {
        this.$request.post({
          url: `${this.$apis.userExtBaseUrl}/${this.user.id}/teams`,
          data: teamIds
        }).then(res => {
          if (res?.code === 1000) {
            this.transferVisible = false
            this.$emit('handle-success')
            this.$message.success('User has been transferred.')
          }
        })
      }
    },

    submitRole () {
      /**
       * 提交用户角色
       */
      const submit = (isSelf) => {
        this.$request.post({
          url: `${this.$apis.userExtBaseUrl}/${this.user.id}/roles`,
          data: this.userRoleIds
        }).then(res => {
          if (res?.code === 1000) {
            this.$emit('handle-success')
            this.roleVisible = false
            this.$message.success(this.$t('userRoleConfigSuccess'))
            // 修改的是当前登录用户的角色，则需要重新登录以便获取新的权限
            if (isSelf) {
              this.logout()
            }
          }
        })
      }
      if (this.userRoleIds.length) {
        const currentUser = this.$store.state.user
        if (currentUser.email === this.user.email) {
          this.$confirm(this.$t('loginAgainAfterRoleChange'), this.$t('relogin'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            submit(true)
          })
        } else {
          submit(false)
        }
      }
    },
    setRoleColor () {
      /**
       * 设置左上角角色标签的颜色
       */
      const isCurrent = this.user.email === this.currentUser.email
      const isCompanyAdmin = (this.user.roles || []).some(item => item.code === this.$roleCode.companyAdmin)
      const isCompanyManager = (this.user.roles || []).some(item => item.code === this.$roleCode.companyManager)
      return {
        'is-current': isCurrent,
        'is-company-admin': isCompanyAdmin,
        'is-company-manager': isCompanyManager
      }
    }
  },
  watch: {
    transferVisible (newValue) {
      if (newValue) {
        const whereTeamIds = (this.user.teams || []).map(item => item.id)
        this.teamList = this.teams.map(item => {
          const isIn = whereTeamIds.includes(item.id)
          return {
            id: item.id,
            name: item.name,
            isIn
          }
        })
      }
    }
  },
  filters: {
    teamFormat (value) {
      const teams = value.teams || []
      const teamNames = teams.map(item => item.name)
      return teamNames.join('/')
    },
    roleFormat (value) {
      const roles = value.roles || []
      const roleNames = roles.map(item => item.description || item.code)
      return roleNames.join('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
